import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const BraidingHair = () => (
  <Layout>
    <SEO
      title="Tips on Box Braids For Black Hair"
      description="What you need to think before you get your box braids done. Avoiding hair loss and pain. Where to buy, different styles and the difference between synthetic and human braiding hair"
    />
    <h1>Top Tips on Box Braids For Black Hair</h1>
    <h2 style={{color:"Orchid"}}>What you need to know before you get your box braids done</h2>
    <p>When it comes to protective styles, single braids or box braids are definitely one of the most popular. They are used worldwide, all year round by women with all different hair types. They are versatile in that they can be braided in different sizes, lengths and come in any colour you want.</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/srqoojn.jpg"
        alt="Tips on Box Braids For Black Hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@0514design?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Tahiti Spears</a></p>
    </div>
    <p>One of the biggest benefits of braids is that you generally use braiding hair extensions so your hair doesn’t need to be long. There just has to be enough length so the stylist can catch the hair well and they don’t fall out within a few days. About a stylist, if you have enough time and patience, you can even do your braids yourself if you are willing to learn.</p>

    <h2>Benefits of braids</h2>
    <h3 style={{color:"Orchid"}}>Long Lasting</h3>
    <p>Look after them well and they can last a very long time. The smaller they are, the longer they are likely to last. Some people like to keep them in longer so they wash their roots every so often to clean their scalp. If you do this, just make sure to towel try and pat not scrub to keep your hair as neat as possible. Definitely don’t use a blow dryer, it will frizz out your roots.</p>
    <h3 style={{color:"Orchid"}}>Growth</h3>
    <p>I mentioned above how braids are a protective style. It’s not uncommon to take out your braids after having them in for a while and see a noticeable difference in hair length.</p>

    <h2>Disadvantages of braids</h2>
    <h3 style={{color:"Orchid"}}>Weight</h3>
    <p>If you haven’t had braids before, the weight of them might surprise you. You will notice the added weight on your head. Most people get over it quickly but to some it can put a strain on your neck and cause discomfort. Hair is not that heavy but when you have 2 or 3x the weight of hair on your head than you are used to then it’s okay to feel a bit strange.</p>
    <h3 style={{color:"Orchid"}}>Hair loss/ edges</h3>
    <p>If you have weak edges, it might be best to avoid braids. They will probably make your already weak edges weaker if they are not styled properly. Have a conversation with your stylist before you go ahead. Ask them about what they can do to protect your edges and experiences they have seen others have before.</p>
    <h3 style={{color:"Orchid"}}>Pain/Too tight</h3>
    <p>When you are getting your braids or hair done in general, the rule to get it to last long is - the tighter the better. Obviously too tight comes with its problems so it’s best to find a balance. Too light and you will have a painful scalp for a while.</p>
    <p>I have seen too many pictures of ladies with sore red scalps because the stylist did their hair too tight. Fortunately, braids are done one at a time so if you feel pain make sure you say something straight away. You don’t want to try to bear it and end up taking them out when you get home because it was just too much.</p>

    <h2>Our Tips</h2>
    <h3 style={{color:"Orchid"}}>Buying braids</h3>
    <p>If you are going to get your hair done. There are some ways you can save money. You can go to the stylist and they might have some braiding hair in stock that you can choose from. You could also go to your local hair shop and might see some for a similar price.</p>
    <p>An alternative is to buy online. Online sellers often sell a higher volume of hair so they order more and can afford to sell for cheaper.</p>

    <h3 style={{color:"Orchid"}}>Types of braids</h3>
    <p>There are so many styles of braids, you might even have one in mind that you want to try. In case you need some inspiration, here are some you could take a look at. There are plenty of images online, just have a search.</p>
    <h4>Faux locs</h4>
    <p>Your hair is braided normally then you wrap the locs around it to give the effect of having dreadlocks. They don’t look like real dreadlocks at all but have a distinctive style that people have come to recognise.</p>
    <h4>Twists</h4>
    <p>Twist the hair extensions with your hair instead of plaiting. They look really nice if that’s your thing but they don’t usually last anywhere as long.</p>
    <h4>Chunky</h4>
    <p>Make each braid a lot thicker than usual. You can do this with a combination of dividing larger sections of your hair and using more braiding hair.</p>
    <h4>Knotless</h4>
    <p>This is where you start braiding the hair away from the base to avoid the big not that gives away you are wearing braids. It looks more natural but won’t last as long.</p>
    <h4>Loose</h4>
    <p>Start braiding the hair as normal then stop part way. You will get the braided look with loose hair at the ends. It works best with wavy or curly hair to get volume and a purposeful look.</p>

    <h3 style={{color:"Orchid"}}>Hair Type</h3>
    <h4>Synthetic braiding hair</h4>
    <p>Synthetic hair is the most cost-effective way of getting box braids. They are available pretty much anywhere and come in a wide variety of colours & styles. I mean curly, wavy, straight etc.</p>
    <p>The downside to synthetic hair is that it can look unnatural and if you don’t match your hair carefully, it will be easy to tell where your hair ends and the extensions begin. This is more obvious in wigs and straight styles, you have more flexibility with braids.</p>

    <h4>Human braiding hair</h4>
    <p>Human hair is more expensive than synthetic. The advantages are it is easy to work with. It holds style like natural hair would because it is and it holds colour better. Like synthetic, you can get it in various styles and colours to suit your needs.</p>
    <p>The only real downside is the price. If you are careful with taking the braids out, you can wash them and use them again in future.</p>





    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/wigs-for-black-women/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Wigs For Black Women
            </Link>{" "}
            - The surprising reasons a wig might be exactly what you need. How a wig can help you on the way to beautiful natural hair...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>    
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default BraidingHair
